import * as React from 'react';
import Grid from '@mui/material/Grid';
import MainButton from '../Components/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import BackgroundImage from '../Components/Image';
import Title from "../Components/Title";
import Collapsible from "../Components/Collapsible"


const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
    content: {
        height: '100%'
    },
    rightColumn: {
        height: '100vh',
        width: '100%'
    },
    textBlock: {
        backgroundColor: '#058482',
        color: '#ffffff',
        padding: 30
    }
});

export default function Location (props) {
    const classes = useStyles();
    const locationList = [
        {
            aria: 'panel1d-content',
            id: 'panel1d-header',
            panel: 'panel1',
            title: 'Детские сады',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
        {
            aria: 'panel2d-content',
            id: 'panel2d-header',
            panel: 'panel2',
            title: 'Школы',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
        {
            aria: 'panel3d-content',
            id: 'panel3d-header',
            panel: 'panel3',
            title: 'Поликлиники',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
        {
            aria: 'panel4d-content',
            id: 'panel4d-header',
            panel: 'panel4',
            title: 'Торговый центр',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
        {
            aria: 'panel5d-content',
            id: 'panel5d-header',
            panel: 'panel5',
            title: 'Общественный транспорт',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
    ]
    return (
        <Grid
            container
            spacing={0}
            columns={8}
            direction="row"
            className={classes.root}
            id="location"
        >

            <Grid item xs={3} className={classes.content}>

                <Grid
                    container
                    className={classes.rightColumn}
                    direction="column"
                    alignItems="stretch"
                    columns={10}
                >

                    <Title backgroundColor="grey" textColor="white" text="РАСПОЛОЖЕНИЕ"/>

                    <Grid item xs={6.5} className={classes.textBlock}>
                        {/* <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Виды жилого комплекса «Отрада» покорят вас невероятно,
                            это же несколько невысоких домов в прекрасном районе
                            с самыми лучшими закатами и всё такое.
                        </Typography> */}
                        <Collapsible section="location" collapsibleItems={locationList}/>
                    </Grid>

                    <MainButton backgroundColor="pink" textColor="white" text="Узнать больше" openForm={props.openForm}/>

                </Grid>

            </Grid>

            <Grid item xs={5} className={classes.content}>
                <BackgroundImage image="img5"/>
            </Grid>

        </Grid>
    );
}