import * as React from 'react';
import Grid from '@mui/material/Grid';
import MainButton from '../Components/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import BackgroundImage from '../Components/Image';
import Title from "../Components/Title";
import Collapsible from "../Components/Collapsible"
import ImageModal from '../Components/ImageModal'
import img12 from '../Images/12.png';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';


const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
    content: {
        height: '100%'
    },
    rightColumn: {
        height: '100vh',
        width: '100%'
    },
    textBlock: {
        backgroundColor: '#5e5e5e',
        color: '#ffffff',
        padding: 30
    },
    parallax: {
        height: '100%',
     }, 
     parallaxImage: {
         backgroundPosition: 'center',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         cursor: 'zoom-in',
     }
});


export default function Dignities (props) {
    const classes = useStyles();
    const [modal, setModal] = React.useState(null);

    const dignitiesList = [
        {
            aria: 'panel1d-content',
            id: 'panel1d-header',
            panel: 'panel1',
            title: 'Архитектура',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых. На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый отдых. '
        },
        {
            aria: 'panel2d-content',
            id: 'panel2d-header',
            panel: 'panel2',
            title: 'Инфраструктура',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение, воспитание детей и здоровый \
            отдых.'
        },
        {
            aria: 'panel3d-content',
            id: 'panel3d-header',
            panel: 'panel3',
            title: 'Парковка',
            text: 'На территории комплекса мы создаем атмосферу, \
            вдохновляющую на общение.'
        },
    ]
    return (
        <>
            <ImageModal modal={modal} setModal={() => setModal(false)} image={modal} close={() => setModal(false)}></ImageModal>

            <Grid
                container
                spacing={0}
                columns={8}
                direction="row"
                className={classes.root}
                id="dignities"
            >

                <Grid item xs={3} className={classes.content}>

                    <Grid
                        container
                        className={classes.rightColumn}
                        direction="column"
                        alignItems="stretch"
                        columns={10}
                    >

                        <Title backgroundColor="pink" textColor="white" text="ДОСТОИНСТВА"/>

                        <Grid item xs={6.5} className={classes.textBlock}>
                            {/* <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                Виды жилого комплекса «Отрада» покорят вас невероятно,
                                это же несколько невысоких домов в прекрасном районе
                                с самыми лучшими закатами и всё такое.
                            </Typography> */}
                            <Collapsible section="dignities" collapsibleItems={dignitiesList}/> 
                        </Grid>

                        <MainButton backgroundColor="green" textColor="yellow" text="Оставить заявку" openForm={props.openForm}/>

                    </Grid>

                </Grid>

                <Grid item xs={5} className={classes.content}>
                    <ParallaxBanner style={{ aspectRatio: '1' }} className={classes.parallax}>
                        <ParallaxBannerLayer image={img12} speed={10} className={classes.parallaxImage} />
                    </ParallaxBanner>
                </Grid>

            </Grid>
        </>
    );
}