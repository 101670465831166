import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MyInput from '../Components/Input';
import Grid from '@mui/material/Grid';
import logo from '../Images/logotip.jpg';

const theme = createTheme({
    palette: {
        primary: {
            main: '#f2f3e2',
        },
        secondary: {
            main: '#058482',
        },
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f2f3e2',
    color: '#ffffff',
    boxShadow: 'none',
    p: 4,
};

const useStyles = makeStyles({
    logo: {
        width: 150
    },
    buttonBlock: {
        paddingTop: 30,
        paddingBottom: 10
    },
    button: {
        boxShadow: 'none !important'
    },
    closeButton: {
        position: 'absolute !important',
        right: 10,
        top: 10,
        fontSize: '25px !important',
        color: 'rgba(0, 0, 0, 0.6) !important'
    }
});

export default function MyModal(props) {
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button onClick={props.handleClose} className={classes.closeButton}>×</Button>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center">
                        <Grid item>
                            <img alt="logo" src={logo} className={classes.logo} />
                        </Grid>
                        <Grid item>
                        <script data-b24-form="inline/17/91oqv4" data-skip-moving="true">
                            {(function(w,d,u){
                                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
                                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
                            })(window,document,'https://b24.avbkuban.ru/upload/crm/form/loader_17_91oqv4.js')}
                        </script>
                            {/* <MyInput />
                        </Grid>
                        <Grid item className={classes.buttonBlock}>
                            <ThemeProvider theme={theme}>
                                <Button variant="contained" color="secondary" className={classes.button}>Отправить</Button>
                            </ThemeProvider> */}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
