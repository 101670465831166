import * as React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Title from "../Components/Title";
import img8 from "../Images/10.png";
import logo from '../Images/logotip.jpg';
import FooterMenu from "../Components/FooterMenu";
import FooterContacts from "../Components/FooterContacts";
import { ParallaxBanner } from 'react-scroll-parallax';

const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
    background: {
        backgroundImage: `url(${img8})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
    },
    blockContent: {
        height: '100%'
    },
    content: {
        height: '100%'
    },
    rightColumn: {
        height: '100%',
        width: '100%',
        "& div": {
            zIndex: '1 !important',
        }
    },
    textBlock: {
        backgroundColor: '#f8f8ea',
        color: '#5e5e5e',
        padding: 30,
    },
    logoBlock: {
        position: 'relative',
    },
    logo: {
        width: 140,
        margin: 0,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)',
    },
    menu: {
        backgroundColor: '#058482'
    },
    footer: {
        backgroundColor: '#5e5e5e'
    }, 
});

export default function Documents() {
    const classes = useStyles();
    return (
        <Grid
            container
            spacing={0}
            columns={11}
            direction="column"
            className={classes.root}
            id="footer"
        >
            <Grid item xs={8}>
                <ParallaxBanner 
                    className={classes.background}
                    layers={[
                        { image: img8, speed: 0 },
                    ]}
                >
                
                    <Grid
                        container
                        spacing={0}
                        columns={10}
                        direction="row"
                        className={classes.blockContent}
                    >

                        <Grid item xs={2} className={classes.logoBlock}>
                            <a href="#aboutComplex">
                                <img alt="background" src={logo} className={classes.logo} />
                            </a>
                        </Grid>

                        <Grid item xs={3} className={classes.content}>

                            <Grid
                                container
                                className={classes.rightColumn}
                                direction="column"
                                alignItems="stretch"
                                columns={10}
                            >

                                <Title backgroundColor="grey" textColor="yellow" text="ДОКУМЕНТЫ"/>

                                <Grid item xs={5} className={classes.textBlock}>
                                    <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                        Виды жилого комплекса «Отрада» покорят вас невероятно,
                                        это же несколько невысоких домов в прекрасном районе
                                        с самыми лучшими закатами и всё такое.
                                    </Typography>
                                </Grid>

                                <Grid item xs={3}/>
                            </Grid>

                        </Grid>

                        <Grid item xs={5} className={classes.content}>
                        </Grid>

                    </Grid>
                </ParallaxBanner>
            </Grid>
            <Grid item xs={2} className={classes.menu}>
                <FooterMenu/>
            </Grid>
            <Grid item xs={1} className={classes.footer}>
                <FooterContacts/>
            </Grid>

        </Grid>
    );
}