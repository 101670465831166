import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {makeStyles} from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const useStyles = makeStyles({
    form: {
        textAlign: 'center'
    },
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#f2f3e2',
        },
        secondary: {
            main: '#058482',
        },
    },
});


export default function MyInput() {
    const classes = useStyles();

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      className={classes.form}
    >
        <ThemeProvider theme={theme}>
            <TextField id="name" label="Имя" variant="outlined" color="secondary"/>
            <TextField id="number" label="Номер телефона" variant="outlined" color="secondary"/>
        </ThemeProvider>
    </Box>
  );
}
