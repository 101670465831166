import * as React from 'react';
import Grid from '@mui/material/Grid';
import MainButton from '../Components/Button';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import BackgroundImage from '../Components/Image';
import ImageModal from '../Components/ImageModal'
import img8 from '../Images/8.png';
import img9 from '../Images/9.png';
import img10 from '../Images/10.png';
import img11 from '../Images/11.png';
import img12 from '../Images/12.png';
import img18 from '../Images/18.png';
import img19 from '../Images/19.png';
import img20 from '../Images/20.png';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';



// const Component = (props) => (

//     // <Parallax translateY={[-20, 20]} layers={[
//     //     {
//     //       speed: -10,
//     //       ...props,
//     //     },
//     //   ]}>
//     //     <BackgroundImage className="my-thing" image="img10"/>
//     //      {/* click={() => { setModal(img10) }}/> */}
//     // </Parallax>
// );

const useStyles = makeStyles({
    root: {
        height: '100vh'
    },
    content: {
        height: '100%'
    },
    rightColumn: {
        height: '100vh',
        width: '100%',
    },
    textBlock: {
        backgroundColor: '#058482',
        color: '#ffffff',
        padding: 30
    },
    parallax: {
       height: '100%',
    }, 
    parallaxImage: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //height: '100%',
        cursor: 'zoom-in',
    }
});

export default function AboutComplex(props) {
    const [modal, setModal] = React.useState(null);
    const classes = useStyles();
    return (
        <>
            <ImageModal modal={modal} setModal={() => setModal(false)} image={modal} close={() => setModal(false)}></ImageModal>

            <Grid
                container
                spacing={0}
                columns={8}
                direction="row"
                className={classes.root}
                id="aboutComplex"
            >


                <Grid item xs={5} className={classes.content} click={() => { setModal(img12) }}>
                    <ParallaxBanner style={{ aspectRatio: '1' }} className={classes.parallax}>
                        <ParallaxBannerLayer image={img12} speed={20} className={classes.parallaxImage} />
                    </ParallaxBanner>
                </Grid>

                <Grid item xs={3} className={classes.content}>

                    <Grid
                        container
                        className={classes.rightColumn}
                        direction="column"
                        columns={10}
                        alignItems="stretch"
                    >

                        <Grid item xs={5} click={() => { setModal(img11) }}>
                            <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className={classes.parallax}>
                                <ParallaxBannerLayer image={img11} speed={-10} className={classes.parallaxImage} />
                            </ParallaxBanner>
                        </Grid>

                        <Grid item xs={3.5} className={classes.textBlock}>
                            <Typography variant="h5" sx={{textAlign: 'center'}} gutterBottom>
                                ЖИЛОЙ КОМПЛЕКС «ОТРАДА»
                            </Typography>
                            <Typography variant="body1" sx={{textAlign: 'left'}}>
                                Виды жилого комплекса «Отрада» покорят вас невероятно,
                                это же несколько невысоких домов в прекрасном районе
                                с самыми лучшими закатами и всё такое.
                            </Typography>
                        </Grid>
                        <MainButton backgroundColor="yellow" textColor="grey" text="Оставить заявку" openForm={props.openForm}/>
                    </Grid>

                </Grid>

            </Grid>
        </>
    );
}