import * as React from 'react';
import {
  styled
} from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';






export default function Collapsible(props) {
  const Accordion = styled((innerProps) => (
    <MuiAccordion
      disableGutters
      elevation={0}
      square
      {...innerProps}
    />
  ))(() => ({
    backgroundColor: props.section == 'dignities' ? '#5e5e5e' : '#058482',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((innerProps) => (<
    MuiAccordionSummary expandIcon={
      < CircleIcon sx={
        {
          fontSize: '0.9rem'
        }
      }
      />} {
    ...innerProps
    }
  />
  ))(({
    theme
  }) => ({
    backgroundColor: props.section == 'dignities' ? '#5e5e5e' : '#058482',
    color: '#ffffff',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'transparent',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: props.section == 'dignities' ? '#eea0ba' : 'transparent',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary-content .MuiTypography-root': {
      borderBottom: '1px solid #ffffff'
    },
    '& .MuiAccordionSummary-content.Mui-expanded .MuiTypography-root': {
      borderBottom: 'none'
    }
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({
    theme
  }) => ({
    padding: theme.spacing(2),
    color: '#ffffff',
    marginLeft: theme.spacing(2.5),
    '& .MuiTypography-root': {
      borderBottom: props.section === 'dignities' ? 'none' : '1px solid white'
    }
  }));

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { section, dot, content, underline, ...other } = props;

  return (<div>
      {props.collapsibleItems.map(elem => {
        return (
          <Accordion
            expanded={expanded === elem.panel}
            onChange={
              handleChange(elem.panel)
            }
          >
            <AccordionSummary aria-controls={elem.aria} id={elem.id}>
              <Typography variant="body1"> {elem.title} </Typography> 
            </AccordionSummary> 
            <AccordionDetails >
              <Typography variant="body2">
                {elem.text}
              </Typography> 
            </AccordionDetails> 
        </Accordion>
        );
      })}        
    </div>
  );
}