import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles({

  root: {
    background: (props) => {
      let b = props.backgroundColor
          return (
              b === 'yellow' ? '#f3f3e2 !important'
                  : b === 'green' ? '#058482 !important'
                      : b === 'pink' ? '#eea0ba !important'
                        : b === 'grey' ? '#5e5e5e !important'
                            : 'none'
          )
    }
      ,
    border: 0,
    borderRadius: '0 !important',
    color: (props) =>
      props.textColor === 'grey' ? '#5e5e5e !important'
      : 'yellow' ? '#f3f3e2 !important'
      : 'white' ? '#ffffff !important'
      : 'none',
    height: '100%',
    width: '100%',
  },
});

function MyButton(props) {
  const { backgroundColor, textColor, openForm, ...other } = props;
  const classes = useStyles(props);
  return <Button className={classes.root} onClick={openForm} {...other} />;
}

MyButton.propTypes = {
  backgroundColor: PropTypes.oneOf(['yellow', 'green', 'pink']).isRequired,
  textColor: PropTypes.oneOf(['grey', 'yellow', 'white']).isRequired,
};

export default function MainButton(props) {
  return (
    <Grid item xs={1.5}>
      <MyButton backgroundColor={props.backgroundColor} textColor={props.textColor} openForm={props.openForm}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
           {props.text}
          </Typography>
        </MyButton>
    </Grid>
  );
}