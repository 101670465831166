import * as React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import logo from '../Images/7.png';


const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '90%',
        marginLeft: '5%'
    },
    elem: {
        fontSize: 12,
        color: '#ffffff'
    },
    logo: {
        width: 70
    }
});

export default function FooterContacts() {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={18}
            className={classes.root}
        >
        
            <Grid item xs={6} className={classes.elem}>  
                Офис продаж
                <br/>
                Краснодар, Олимпийский проспект, дом 16
                <br/>
                С 10:00 до 19:00, ежедневно
            </Grid>

                    
            <Grid item xs={6} className={classes.elem}>  
                + 7 961 245 23 45
                <br/>
                otrada@regionstroy.ru
            </Grid>

                    
            <Grid item xs={6} 
                className={classes.elem} 

            >  
                <Grid container 
                direction="row"  
                columns={6}                 
                justifyContent="center"
                alignItems="center"
                spacing={0}
                >
                    <Grid item xs={2}>
                        Застройщик 
                        <br/>
                        ООО «Регион-строй»
                    </Grid>
                    <Grid item className={classes.logo} xs={2}>
                        <img alt="background" src={logo} className={classes.logo} />
                    </Grid>
                </Grid>
            </Grid>
                
            
        </Grid>
    );
}