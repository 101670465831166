import * as React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '90%',
        marginLeft: '5%'
    },
    // menuElem: {
    //     textAlign: 'center',
    // },
    link: {
        textDecoration: 'none',
        color: '#ffffff'
    }
});

const menuItems = [{
    title: "О КОМПЛЕКСЕ", link: "#aboutComplex"
}, {
    title: "ДОСТОИНСТВА", link: "#dignities"
}, {
    title: "ПЛАНИРОВКИ", link: "#plans"
}, {
    title: "РАСПОЛОЖЕНИЕ", link: "#location"
}, {
    title: "ДОКУМЕНТЫ", link: "#footer"
},]

export default function FooterMenu() {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={10}
            className={classes.root}
        >
        
                {menuItems.map(elem => {
                    return (
                        <Grid item xs={2} className={classes.menuElem}>  
                            <a href={elem.link} className={classes.link}>
                                {elem.title}
                            </a>
                        </Grid>
                    );
                })}
            
        </Grid>
    );
}