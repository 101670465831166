import * as React from 'react';
import AboutComplex from './Sections/AboutComplex';
import Dignities from './Sections/Dignities';
import './App.css';
import Grid from '@mui/material/Grid';
import {makeStyles} from '@mui/styles';
import Navigation from './Components/Menu';
import Plans from './Sections/Plans'
import Location from "./Sections/Location";
import Documents from "./Sections/Documents";
import MyModal from './Components/Modal';
import { ParallaxProvider } from 'react-scroll-parallax';

const useStyles = makeStyles({
    column: {
        height: '400vh'
    }
});


function App () {
    const [isOpened, setIsOpened] = React.useState(false);
    const classes = useStyles();
    
    return (
        <ParallaxProvider>
            <Grid container direction="row" columns={10} className={classes.root}>
                <Grid item xs={2} className={classes.column}>
                    <Navigation/>
                </Grid>
                <Grid item xs={8} className={classes.column}>
                    <Grid container direction="column">
                        <Grid item>
                            <AboutComplex openForm={() => setIsOpened(true)}/>
                        </Grid>
                        <Grid item>
                            <Dignities openForm={() => setIsOpened(true)}/>
                        </Grid>
                        <Grid item>
                            <Plans openForm={() => setIsOpened(true)}/>
                        </Grid>
                        <Grid item>
                            <Location openForm={() => setIsOpened(true)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Documents/>
                </Grid>
            </Grid>
            <MyModal isOpen={isOpened} handleClose={() => setIsOpened(false)}/>
        </ParallaxProvider>
    );
}

export default App;
