import * as React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, propsToClassKey} from '@mui/styles';
import img1 from '../Images/1.png';
import img2 from '../Images/2.png';
import img3 from '../Images/3.png';
import img4 from '../Images/4.png';
import img5 from '../Images/5.png';
import img8 from '../Images/8.png';
import img9 from '../Images/9.png';
import img10 from '../Images/10.png';
import img11 from '../Images/11.png';
import img12 from '../Images/12.png';
import img13 from '../Images/13.png';
import img14 from '../Images/14.png';
import img15 from '../Images/15.png';
import img16 from '../Images/16.png';
import img17 from '../Images/17.png';

let imgArray = [
    'img1', 'img2', 'img3', 'img4', 'img5', 'img8', 'img9', 'img10', 'img11', 'img12', 
    'img13', 'img14', 'img15', 'img16', 'img17'
]


const useStyles = makeStyles({
    root: {
        backgroundImage: (props) => {
            let b = props.background
            return (b === 'img1' ? `url(${img1})`
                : b === 'img2' ? `url(${img2})`
                    : b === 'img3' ? `url(${img3})`
                        : b === 'img4' ? `url(${img4})`
                            : b === 'img5' ? `url(${img5})`
                                : b === 'img8' ? `url(${img8})`
                                    : b === 'img9' ? `url(${img9})`
                                        : b === 'img10' ? `url(${img10})`
                                            : b === 'img11' ? `url(${img11})`
                                                : b === 'img12' ? `url(${img12})`
                                                    : b === 'img13' ? `url(${img13})`
                                                        : b === 'img14' ? `url(${img14})`
                                                            : b === 'img15' ? `url(${img15})`
                                                                : b === 'img16' ? `url(${img16})`
                                                                    : b === 'img17' ? `url(${img17})`
                                                                        : 'none')
        },
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        cursor: 'zoom-in',
    }
});

function MyImage(props) {
    const {background, ...other} = props;
    const classes = useStyles(props);
    return <div className={classes.root} onClick={props.click} {...other} />;
}

MyImage.propTypes = {
    background: PropTypes.oneOf(imgArray).isRequired,
};

export default function BackgroundImage(props) {
    return (
        <MyImage background={props.image} key={props.image} click={props.click}/>
    );
}