import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles({
    root: {
        background: (props) => {
            let b = props.backgroundColor
            return (
                b === 'grey' ? '#5e5e5e'
                    : b === 'green' ? '#058482'
                        : b === 'pink' ? '#eea0ba'
                            : 'none'
            )
        },
        border: 0,
        borderRadius: 0,
        paddingBottom: 20,
        paddingLeft: 40,
        color: (props) => {
            let c = props.textColor
            return (
                c === 'grey' ? '#5e5e5e'
                    : c === 'yellow' ? '#f3f3e2'
                        : c === 'white' ? '#ffffff'
                            : c === 'none'
            )
        },
        height: '100%',
        width: '100%',
    }
});

function MyTitle(props) {
    const { backgroundColor, textColor, ...other } = props;
    const classes = useStyles(props);
    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            className={classes.root}
            {...other}
        >
        </Grid>
    )
}


MyTitle.propTypes = {
    backgroundColor: PropTypes.oneOf(['yellow', 'green', 'pink', 'grey']).isRequired,
    textColor: PropTypes.oneOf(['grey', 'yellow', 'white']).isRequired,
};

export default function Title(props) {
    return (
        <Grid item xs={2}>
            <MyTitle backgroundColor={props.backgroundColor} textColor={props.textColor}>
                <Grid item>
                    <Typography variant="h5">
                        {props.text}
                    </Typography>
                </Grid>
            </MyTitle>
        </Grid>
    );
}