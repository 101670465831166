import * as React from 'react';
import Grid from '@mui/material/Grid';
import MainButton from '../Components/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import BackgroundImage from '../Components/Image';
import Title from "../Components/Title";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Button from '@mui/material/Button';
import img4 from '../Images/4.png';
import img13 from '../Images/13.png';
import img14 from '../Images/14.png';
import img15 from '../Images/15.png';
import img16 from '../Images/16.png';
import img17 from '../Images/17.png';


const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
    content: {
        height: '100%',
        display: 'block'
    },
    rightColumn: {
        height: '100vh',
        width: '100%'
    },
    textBlock: {
        backgroundColor: '#f8f8ea',
        color: '#5e5e5e',
        height: '100%'
    },
    sliderButtonsBlock: {
        backgroundColor: '#bec5c5',
        height: 120
    }, 
    darkGreyBlock: {
        backgroundColor: '#5e5e5e',
        height: '100%',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#ffffff',
        textAlign: 'center',
    },
    sliderTitle: {
        paddingTop: 30,
    },
    textPart: {
        padding: 30,
        paddingTop: 50
    },
    arrowButton: {
        height: '100%',
        color: 'grey !important'
    },
    planImage: {
        maxWidth: 400,
        height: 'auto',
        maxHeight: 600,
        display: 'block',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    contentManaging: {
        height: '100%'
    }
});

export default function Plans (props) {
    const plans = [
        {
            id: 1,
            area: '24,5',
            rooms: 1,
            image: img4,
            description: 'Описание преимуществ квартиры и возможности отделки'
        },
        {
            id: 2,
            area: '48,5',
            rooms: 2,
            image: img13,
            description: 'Расписание жоп и отделок'
        },
        {
            id: 3,
            area: '68,5',
            rooms: 2,
            image: img14,
            description: 'Написание карт и разделок'
        },
        {
            id: 4,
            area: '4,5',
            rooms: 1,
            image: img15,
            description: 'Записание дарт и подделок'
        },
        {
            id: 5,
            area: '61,5',
            rooms: 2,
            image: img16,
            description: 'Выжигание слов и гляделок'
        },
        {
            id: 6 ,
            area: '14,5',
            rooms: 2,
            image: img17,
            description: 'Разжигание снов и пропелок'
        },
    ]
    
    const classes = useStyles();
    const [image, chooseImage] = React.useState(0);


    const scrollFlat = (side) => {
        if (side === 'prev' && image>0) {
            chooseImage(+image-1)
        }
        if (side === 'next' && image<plans.length-1) {
            chooseImage(+image+1)
        }
    }

    return (
        <Grid
            container
            spacing={0}
            columns={8}
            direction="row"
            className={classes.root}
            id="plans"
        >

            <Grid item xs={3} className={classes.content}>

                <Grid
                    container
                    className={classes.rightColumn}
                    direction="column"
                    alignItems="stretch"
                    columns={10}
                >

                    <Title backgroundColor="green" textColor="white" text="ПЛАНИРОВКИ" />

                    <Grid item xs={6.5} className={classes.textBlock}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="stretch"
                        >
                            <Grid item className={classes.sliderButtonsBlock}>
                                <Grid container className={classes.sliderButtonsBlock}>
                                    <Grid item>
                                        <Button className={classes.arrowButton} variant="text" onClick={() => scrollFlat('prev')}>
                                            <ArrowBack color="action" />
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.darkGreyBlock}>
                                        <Typography variant="subtitle1" className={classes.sliderTitle}>
                                            {plans[image].rooms}х комнатная квартира
                                        </Typography>
                                        <Typography variant="h5">
                                            {plans[image].area} м²
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classes.arrowButton} variant="text" onClick={() => scrollFlat('next')}>
                                            <ArrowForward color="action" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" sx={{ textAlign: 'left' }} className={classes.textPart}>
                                    {plans[image].description}
                                </Typography>
                            </Grid>
                            
                            </Grid>
                        </Grid>

                        <MainButton backgroundColor="pink" textColor="white" text="Получить консультацию" openForm={props.openForm}/>

                    </Grid>

                </Grid>

                <Grid item xs={5} className={classes.content}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.contentManaging}
                    >
                        <Grid item>
                            <img alt='Планировка квартиры' src={plans[image].image} className={classes.planImage}/>
                        </Grid>
                    </Grid>
                   
                </Grid>

            </Grid>
            );
}