import * as React from 'react';
import {makeStyles} from '@mui/styles';
import logo from '../Images/logotip.jpg';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';


const useStyles = makeStyles({
    root: {
        backgroundColor: '#f3f3e5',
        width: '100%',
        height: '400vh'
    },
    stickyMenu: {
        position: 'sticky',
        top: 0,
        width: '100%'
    },
    menu: {
        height: '100vh',
    },
    content: {
        width: '100%',
        position: 'relative'
    },
    logo: {
        width: 140,
        margin: 0,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        top: 40
    },
    text: {
        paddingLeft: 40,
        color: '#058482'
    },
    icon: {
        color: '#5e5e5e',
        paddingLeft: 10,
        cursor: 'pointer'
    },
    empty: {
        height: '100vh',
        width: '100%'
    },
});
const menuItems = [{
    title: "О КОМПЛЕКСЕ", link: "#aboutComplex"
}, {
    title: "ДОСТОИНСТВА", link: "#dignities"
}, {
    title: "ПЛАНИРОВКИ", link: "#plans"
}, {
    title: "РАСПОЛОЖЕНИЕ", link: "#location"
}, {
    title: "ДОКУМЕНТЫ", link: "#footer"
},]

export default function Navigation() {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            className={classes.root}
            alignItems="center"
        >
            <Grid item className={classes.stickyMenu}>
                <Grid
                    container
                    direction="column"
                    className={classes.menu}
                    alignItems="center"
                >
                    <Grid item xs={3} className={classes.content}>
                        <a href="#aboutComplex">
                            <img alt="logo" src={logo} className={classes.logo}/>
                        </a>
                    </Grid>
                    <Grid item xs={6} className={classes.content}>
                        <List>
                            {menuItems.map(elem => {
                                return (<ListItem disablePadding key={elem.title}>
                                    <ListItemButton key={elem.title} component="a" href={elem.link}>
                                        <ListItemText key={elem.title} primary={elem.title}
                                                      className={classes.text}/>
                                    </ListItemButton>
                                </ListItem>);
                            })}

                        </List>
                    </Grid>
                    <Grid item xs={1}>
                        <YouTubeIcon fontSize="large" className={classes.icon}/>
                        <WhatsAppIcon fontSize="large" className={classes.icon}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={classes.empty}>
            </Grid>
            <Grid item className={classes.empty}>
            </Grid>
            <Grid item className={classes.empty}>
            </Grid>

        </Grid>);
}